import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Gallery from '../components/gallery/gallery'
import styled from "styled-components"

const Section = styled.div`
  max-width: 960px;
  margin 0 auto;
  @media (max-width: 768px) {
    padding: 20px;
  }
`

const GalleryPage = ({ data }) => {
  const images = data.images.edges.map(({ node }) => ({
    ...node.childImageSharp,
    // Use original name as caption.
    // The `originalName` is queried inside the `thumb` field,
    // but the `Gallery` component expects `caption` at the top level.
    caption: node.childImageSharp.thumb.originalName,
  }))

  // Override some of Lightbox options to localise labels in French
  const lightboxOptions = {

  }

  //Add callback to Lightbox onCloseRequest
  const onClose = () => {
    console.log('Lightbox was closed')
  }

  return (
    <Layout>
      <Seo title="Lee Harrison Building and Maintainence" />
      <div style={{
        paddingBottom: `50px`
      }}>
        <Section>
          <h1>GALLERY</h1>
          <Gallery
            images={images}
            lightboxOptions={lightboxOptions}
            onClose={onClose}
          />
        </Section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
              originalName
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default GalleryPage
